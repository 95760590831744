import React from 'react'
import { BtnFull, BtnFlow, BtnBorder } from './style'
import { Icon } from '../../Icon'

export const ButtonFull = ({ children, icon, reference, ...props }) => (
	<BtnFull {...props} icon={icon} ref={reference}>
		{children}
		<Icon component={icon} />
	</BtnFull>
)
export const ButtonBorder = ({ children, icon, ...props }) => (
	<BtnBorder {...props} icon={icon}>
		{children}
		<Icon component={icon} />
	</BtnBorder>
)
export const ButtonFlow = ({ children, icon, ...props }) => (
	<BtnFlow {...props} icon={icon}>
		{children}
		<Icon component={icon} />
	</BtnFlow>
)
