import { createSlice } from "@reduxjs/toolkit";

export const popupSlice = createSlice({
  name: "popup",
  initialState: {
    isVisible: false,
    data: null,
  },
  reducers: {
    showPopup: (state, { payload }) => {
      state.isVisible = true;
      state.data = payload;
    },
    hidePopup: (state) => {
      state.isVisible = false;
      state.data = null;
      state.isEmailPopup = false;
    },
    getEmailSuccess: (state, { payload }) => {
      state.isVisible = true;
      state.isEmailPopup = true;
    },
  },
});

export const { showPopup, hidePopup, getEmailSuccess } = popupSlice.actions;
export const { reducer } = popupSlice;
