import React from 'react'
import { CheckboxStyle } from './style'
import { Error } from '../Input/style'

const Checkbox = ({ children, isMain, className, register, error, ...props }) => {
	return (
		<CheckboxStyle className={className}>
			<input type='checkbox' ref={register} {...props} />
			<span>
				<div className="checkbox-wrapper">
					<div className="checkbox-check"></div>
				</div>
				{children}
			</span>
			{error && <Error isMain={isMain}>{error.message}</Error>}
		</CheckboxStyle>
	)
}
export default Checkbox 
