import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getProperties} from "../../store/root-thunk";
import {getLocale} from "../../store/root-thunk";
import {SS} from "../../utils/common";

const MainProps = ({children}) => {
    const dispatch = useDispatch()
    const {
        messages,
        shopProperties,
        locale,
        shopId,
        merchantShopCode,
        shopCodes
    } = useSelector((state) => state.root);

    /**
     * getProperties {Function}
     * @description
     * - get main application settings
     * */
    useEffect(() => {
        dispatch(getProperties(shopId))
    }, [shopId, dispatch])

    /**
     * getLocale {Function}
     * @description
     * - get locale if there is no locale at all, but there are main application settings
     * */
    useEffect(()=> {
        if(!locale && shopProperties['shop.language']) {
            dispatch(getLocale(shopId, shopProperties['shop.language']))
        }
    }, [shopProperties, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * getLocale {Function}
     * @description
     * - get locale if there is locale, but there is no specific locale needed at the current time.
     * */
    useEffect(()=> {
        if (locale && !Object.keys(messages[locale]).length) {
            console.log('get initial locale')
            dispatch(getLocale(shopId, locale))
        }
    }, [locale, dispatch])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (merchantShopCode && shopCodes) {
            SS.remove('language')
            dispatch(getProperties(shopCodes[merchantShopCode].marketId));

            setTimeout(()=>{
                if (locale && !Object.keys(messages[locale]).length) {
                    console.log('get secondary locale')
                    dispatch(getLocale(shopCodes[merchantShopCode].marketId, locale));
                }
            }, 200)

        }
    }, [merchantShopCode, dispatch])  // eslint-disable-line react-hooks/exhaustive-deps

    return <div style={{height: '100%'}}> {children} </div>

}

export default MainProps
