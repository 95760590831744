import {
	createStore,
	getDefaultMiddleware,
	applyMiddleware,
} from '@reduxjs/toolkit'
import { compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createReducer from './root-reducer.js'

export default function configureStore(initialState = {}, history) {
	let composeEnhancers = compose

	if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
		if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
			composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
	}

	const middlewares = [
		...getDefaultMiddleware({ thunk: true }),
		routerMiddleware(history),
	]
	const enhancers = [applyMiddleware(...middlewares)]

	const store = createStore(
		createReducer(),
		initialState,
		composeEnhancers(...enhancers)
	)

	store.injectedReducers = {}

	return store
}
