import React, {} from 'react'
import {FooterWrapper, FooterLogoWrapper, FooterLinksWrapper, FooterLink} from "./style";
import {Icon} from "../../../../components/Icon";

const Footer = ({currentTheme}) => {
    let renderFooterLogo = (theme) => {
        switch (theme) {
            case "marysia":
                return <FooterLinksWrapper>
                            <FooterLink href="mailto:customerservice@marysia.com">Contact Us</FooterLink> | <FooterLink
                            target="_blank" href="https://marysia.com/pages/service">View full policy</FooterLink>
                        </FooterLinksWrapper>;
            case "quiz":
                return  <FooterLogoWrapper>
                            <FooterLink href="https://nshift.com/" target="_blank">
                                <Icon component={'nShiftLogo'}/>
                            </FooterLink>
                        </FooterLogoWrapper>
            default:
                return <FooterLogoWrapper>
                            <FooterLink href="https://returnado.com/" target="_blank">
                                <Icon component={'LogoReturnado'}/>
                            </FooterLink>
                        </FooterLogoWrapper>
        }
    }

    return (
        <FooterWrapper>
            { renderFooterLogo(currentTheme.name) }        
        </FooterWrapper>
    )
}
export default Footer
