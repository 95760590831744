import React  from 'react';
import Select  from 'react-select'
import {useSelector, useDispatch} from "react-redux";
import {getFormattedShopCodes, SS, getAlphabeticallySortedList} from "../../utils/common";
import {setShopId} from "../../store/root-slices";
import _ from 'lodash'
//import LanguageSwitcher from "../LanguageProvider/LanguageSwitcher";
//import {useIntl} from "react-intl";
//import {hideMarketControl} from "./slices";
import {MultipleComboBoxSelectStyle, MultipleComboBoxSelectStyleIcon} from "./style";
import {merchantThemes} from "../Variable";

const getHoverOptionStyle = (themeName, color) => {
    return themeName === merchantThemes.toteme ? {
        textDecoration: `underline`,
    } : {
        backgroundColor: color.primary,
        color: color.secondary
    }
}

const MultiMarketComboBox = (props) => {

    const { currentTheme, shopCodes, merchantShopCode } = useSelector((state) => state.root);
    const { isVisible } = useSelector((state) => state.multimarketbox);
    const { color } = currentTheme;
    //const { isLanguageSelectorDisabled=false } = props;
    const marketsOptions = getFormattedShopCodes(shopCodes);
    const isMarketOptions = (marketsOptions) => {
        return marketsOptions && marketsOptions.length > 1 && getAlphabeticallySortedList(marketsOptions)
    }
    let isMarketOptionsAvailable = isMarketOptions(marketsOptions);
    let dispatch = useDispatch()
    /*
        let intl = useIntl()
        let changeMarketTitle = intl.formatMessage({
            id: 'language.change.market',
            defaultMessage: 'change market',
        })
        let isLanguageSwitcherDisabled = useSelector(
            (state) => state.root.shopProperties['widget.lang.select.disabled']
        )
    */
    let combinedProps = {
        ...props,
        styles: {
            menu: (styles) => {
                return {
                    ...styles,
                    outline: 'none',
                    boxShadow: `none`,
                    border: `1px solid ${currentTheme.name === merchantThemes.toteme ? color.primary : color.grayOne}`,
                    borderRadius: `${currentTheme.name === merchantThemes.toteme ? '16px' : 0}`
                }
            },
            option: () => {
                return {
                    position: 'relative',
                    padding:  currentTheme.name === merchantThemes.toteme ? '3px 9px 3px 9px' : '3px 9px 3px 28px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    color: currentTheme.name === merchantThemes.toteme ? color.primary : color.grayThree,
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    ':hover': getHoverOptionStyle(currentTheme.name, color)
                }
            },
            input: (styles) => {
                return {
                    ...styles,
                    outline: 'none'
                }
            },
            control: (styles) => {
                return {
                    ...styles,
                    borderColor: currentTheme.name === merchantThemes.toteme ? color.primary : color.grayOne,
                    padding: 0,
                    boxShadow: 'none',
                    borderRadius: `${currentTheme.name === merchantThemes.toteme ? '32px' : 0}`,

                    ':hover': {
                        borderColor: currentTheme.name === merchantThemes.toteme ? color.primary : color.grayOne
                    }
                }
            },
            placeholder: (styles) => {
                return {
                    ...styles,
                    outline: 'none'
                }
            },
            singleValue: () => {
                return {
                    padding: currentTheme.name === merchantThemes.toteme ? '5px 9px 5px 9px' : '5px 9px 5px 32px',
                    fontWeight: 600,
                    color: currentTheme.name === merchantThemes.toteme ? color.primary : color.grayThree,
                    fontSize: 15,
                }
            }
        }

    }

    /**
     * handlerLanguageSwitcher {Function}
     * @description - action on market select change
     *
     * */
    const handlerLanguageSwitcher = ({ value, marketCode }) => {
        dispatch(setShopId({
            value,
            label: marketCode
        }))

        //dispatch(hideMarketControl())
    }

    /**
     * getMarket {Function}
     * @description - get markets
     *
     * */
    const getMarket = (options, market) => {
        if(market) {
            return _.find(options, (i) => i.marketCode === market.toString())
        } else {
            let cacheMarket = SS.get('shopId')
            if(cacheMarket) {
                return _.find(options, (i) => parseInt(i.marketId, 10) === cacheMarket)
            } else {
                return {}
            }
        }
    }

    //let currentMarket = getCurrentShopCode(shopCodes, merchantShopCode);

    return (
        <MultipleComboBoxSelectStyle>
            {isMarketOptionsAvailable && isVisible && <MultipleComboBoxSelectStyleIcon />}
            {
                isMarketOptionsAvailable && isVisible && <Select
                    {...combinedProps}
                    classNamePrefix='react-select'
                    options={marketsOptions}
                    onChange={handlerLanguageSwitcher}
                    isSearchable={false}
                    defaultValue={getMarket(marketsOptions, merchantShopCode)}
                />
            }

            {/*{
                isMarketOptionsAvailable && isLanguageSwitcherDisabled === 'true' && <Select
                    {...combinedProps}
                    classNamePrefix='react-select'
                    options={marketsOptions}
                    onChange={handlerLanguageSwitcher}
                    isSearchable={false}
                    defaultValue={getMarket(marketsOptions, merchantShopCode)}
                />
            }*/}

            {/*{
                !!!isVisible && !isLanguageSelectorDisabled && <LanguageSwitcher marketFlag={currentMarket ? currentMarket.defaultLanguage: ''} isMarketOptions={isMarketOptionsAvailable} changeMarketTitle={changeMarketTitle} isMarketSwitcherVisible={isVisible} />
            }*/}

        </MultipleComboBoxSelectStyle>

    )
}

export default MultiMarketComboBox
