import styled, { css } from 'styled-components'
import {device, fonts, merchantThemes} from '../../../../components/Variable'
import { rem } from '../../../../utils/common'

export const Bg = styled.div`
	background-color: ${({ theme }) => theme.color.secondary};
	padding: 0;
	width: 100%;
	position: relative;
	z-index: 4;
	transition: top 0.5s;
	top: 0;
	
	${p => p.theme.name !== merchantThemes.toteme && css`
     box-shadow: 0 ${rem(-1)} ${rem(14)} ${rem(2)} rgba(0, 0, 0, 0.2);
     border-radius: ${rem(8)};
   `}
	
	${p => p.theme.name === merchantThemes.woodwood && css`
     box-shadow: 0 ${rem(1)} ${rem(4)} ${({theme}) => theme.color.blackOne};
   `}
`
export const FormStyle = styled.form`
	//border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};

	width: 100%;
	display: block;
	padding: ${rem(40, 32)};
	top: ${rem(80)};
	z-index: 5;
	
	${p => p.theme.name === merchantThemes.toteme && css`
		padding: ${rem(40, 0)};
	`}
	
	@media (${device.tablet}) {
		position: relative;
		top: 0;
		padding: ${rem(22)};
	}
	@media (${device.mobileL}) {
		padding: ${rem(26, 16, 16)};
	}
	
	.main-form-submit-button {
		cursor: pointer;
		background: ${({theme}) => theme.color.primary};
		color: ${({theme}) => theme.color.secondary};
		text-align: center;
		display: block;
		width: 100%;
		padding: ${rem(10, 0)};
		border: ${rem(1)} solid ${({theme}) => theme.color.primary};
		margin: ${rem(0, 0, 13, 0)};
		
		&:hover {
			background: ${({theme}) => theme.color.secondary};
			color: ${({theme}) => theme.color.primary};
			border: ${rem(1)} solid ${({theme}) => theme.color.primary};
		}
		
		${(p) => p.theme.name === merchantThemes.marysia && css`
			font-family: ${fonts.futura};
		`}
		${(p) => p.theme.name === merchantThemes['8848'] && css`
			font-family: ${fonts.futura};
			font-size: ${rem(17)};
		`}
		${(p) => p.theme.name === merchantThemes.woodwood && css`
			font-family: ${fonts.WWBeta8Regular};
		`}
		${(p) => p.theme.name === merchantThemes.aycane && css`
			font-family: ${fonts.IBMPlex};
		`}
		${(p) => p.theme.name === merchantThemes.bymalina && css`
			font-family: ${fonts.AvenirBook};
		`}
		${(p) => p.theme.name === merchantThemes.royal_design && css`
			font-family: ${fonts.openSans};
		`}
		${(p) => p.theme.name === merchantThemes.henrylloyd && css`
			font-family: ${fonts.Gotham};
		`}
		${(p) => p.theme.name === merchantThemes.chimi && css`
			font-family: ${fonts.AmericaExpandedRegular};
		`}
		${(p) => p.theme.name === merchantThemes.musto && css`
			font-family: ${fonts.openSans};
		`}
		${(p) => p.theme.name === merchantThemes.ogoship && css`
			font-family: ${fonts.usual};
		`}
		${(p) => p.theme.name === merchantThemes.toteme && css`
			font-family: ${fonts.MonumentGrotesk};
			font-size: ${rem(15)};
			letter-spacing: ${rem(0.06)};
			line-height: ${rem(20)};
      		border-radius: ${rem(12)};
		`}
		${(p) => p.theme.name === merchantThemes.hh && css`
			font-family: ${fonts.dinPro};
		`}
		${(p) => p.theme.name === merchantThemes.hh_ww && css`
			font-family: ${fonts.dinPro};
		`}
		${(p) => p.theme.name === merchantThemes.sns && css`
			font-family: ${fonts.AktivGroteskEx};
		`}
		${(p) => p.theme.name === merchantThemes.quiz && css`
			font-family: ${fonts.Lato};
		`}
		${(p) => p.theme.name === merchantThemes.indiska && css`
			font-family: ${fonts.BatonTurbo};
		`}
		${(p) => p.theme.name === merchantThemes.sportshopen && css`
			font-family: ${fonts.Poppins};
		`}
		${(p) => p.theme.name === merchantThemes.sportshopen_outlet && css`
			font-family: ${fonts.Poppins};
		`}
		${(p) => p.theme.name === merchantThemes.swedemount && css`
			font-family: ${fonts.GillSansNova};
		`}
		
	}

`
export const FormHeader = styled.div`
	position: relative;
`
export const FormHeaderItem = styled.div`
	&:first-child {
		width: ${rem(200)};
		position: relative;
		z-index: 2;
		margin-bottom: ${rem(30)};
	}
	&:last-child {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 1;
	}
`
export const FormTitle = styled.div`
	text-align: center;
	font-size: ${rem(26)};
	margin-bottom: ${rem(40)};
	
	${p => p.theme.name === merchantThemes.bymalina && css`
        font-size: ${rem(25.74)};
        width: ${rem(225)};
        margin: 0 auto ${rem(20)} auto;
        line-height: ${rem(27)};
    `}
    
	${(p) => p.theme.name === merchantThemes.toteme && css`
		font-size: ${rem(15)};
		letter-spacing: ${rem(0.06)};
		line-height: ${rem(20)};
	`}
	${(p) => p.theme.name === merchantThemes.hh && css`
		font-size: ${rem(20)};
	`}
	${(p) => p.theme.name === merchantThemes.hh_ww && css`
		font-size: ${rem(20)};
	`}
	${(p) => p.theme.name === merchantThemes.quiz && css`
		font-family: ${fonts.Playfair};
	`}
	${(p) => p.theme.name === merchantThemes.indiska && css`
		font-family: ${fonts.PTSerif};
	`}
	
	@media (${device.tablet}) {
		margin-bottom: ${rem(20)};
	}
	@media (${device.mobileL}) {
		margin-bottom: ${rem(26)};
	}
`
export const FormTermsHolder = styled.div`
	text-align: center;
	font-style: italic;
	@media (${device.tablet}) {
		margin-bottom: ${rem(15)};
	}
	@media (${device.mobileL}) {
		margin-bottom: 0;
	}
`
export const Description = styled.p`
	font-size: ${rem(14)};
	line-height: ${rem(20)};
	text-align: center;
	max-width: ${rem(370)};
	margin: 0 auto ${rem(15)};
	
	${(p) => p.theme.name === merchantThemes.toteme && css`
		font-size: ${rem(15)};
		letter-spacing: ${rem(0.06)};
		line-height: ${rem(20)};
	`}
`
export const LinkUnderline = styled.span`
	//font-weight: 600;
	font-size: ${rem(14)};
	line-height: ${rem(18)};
	//display: inline-block;
	text-decoration: underline;
	cursor: pointer;
	transition: color 0.3s;
	margin-bottom: ${rem(5)};
	
	${(p) => p.theme.name === merchantThemes.toteme && css`
		font-size: ${rem(15)};
		letter-spacing: ${rem(0.06)};
		line-height: ${rem(20)};
	`}
	
	&:hover {
		color: ${({ theme }) => theme.color.primary};
	}
	${(p) =>
		!p.orderNum &&
		p.orderLink &&
		css`
			margin-bottom: ${rem(30)};
		`}
`

export const FormSocialViewBack = styled.div`
	position: absolute;
	width: ${rem(38)};
	height: ${rem(38)};
	border: ${rem(1)} solid ${({theme}) => theme.color.blackOne};
	background: ${({theme}) => theme.color.grayTwo};
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
	opacity: 0.7;
	transform: rotate(90deg);
	
	> svg {
	  fill: ${({theme}) => theme.color.blackOne};
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  margin-top: ${rem(-4)};
	  margin-left: ${rem(-8)};
	}
	
	&:hover {
      opacity: 1;
	}
`
