import styled, {css} from 'styled-components'
import { rem } from '../../utils/common'
import { device, merchantThemes } from '../Variable'

export const ReclamationContactFormElem = styled.form``
export const ReclamationContactFormBody = styled.div`
	padding: ${rem(35, 45)};
	
	&.modal {
	  text-align: center;
	}
	
	&.modal_policy,
	&.modal_faq,
	&.modal_shipping,
	&.modal_terms {
		text-align: left;
	}
	
	@media (${device.tabletS}) {
		padding: ${rem(15, 10)};
	}
`
export const ReclamationContactFormClose = styled.div`
	position: absolute;
	cursor: pointer;
	top: 50%;
	margin: ${rem(-11)} ${rem(2)} 0 0;
	right: ${rem(8)};
	border: ${rem(1)} solid ${({ theme }) => theme.color.grayTen};
	padding: ${rem(4)};
`
export const ReclamationContactFormHeader = styled.div`
	position: relative;
	background: ${({ theme }) => theme.color.grayFive};
	text-align: center;
	padding: ${rem(10)};
	
	${p => p.theme.name === merchantThemes.toteme && css`
      background: transparent;
      border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.primary};
    `}
`
export const ReclamationContactFormTitle = styled.div`
	text-transform: uppercase;
	font-size: ${rem(15)};
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: ${rem(0, 22, 0, 0)};
	
    ${(p) => p.theme.name === merchantThemes.toteme && css`
        font-size: ${rem(15)};
        letter-spacing: ${rem(0.06)};
        line-height: ${rem(20)};
        text-transform: none;
    `}
	
	&.model {
	  font-size: ${rem(17)};
	  
    ${(p) => p.theme.name === merchantThemes.toteme && css`
        font-size: ${rem(15)};
        letter-spacing: ${rem(0.06)};
        line-height: ${rem(20)};
    `}
	  
	}
`
export const ReclamationContactFormSubTitle = styled.div`
	font-size: ${rem(14)};
	text-align: center;
	color: ${({ theme }) => theme.color.grayTen};
	margin-bottom: ${rem(20)};
	
	${(p) => p.theme.name === merchantThemes.toteme && css`
        font-size: ${rem(15)};
        letter-spacing: ${rem(0.06)};
        line-height: ${rem(20)};
    `}
`
export const ReclamationContactFormTextArea = styled.textarea`
	border: 1px solid ${({ theme }) => theme.color.blackOne};
	background: ${({ theme }) => theme.color.grayFive};
	min-height: ${rem(120)};
	border-radius: ${rem(3)};
	margin-bottom: ${rem(40)};
	padding: ${rem(10)};
	font-size: ${rem(16)};
	font-family: Arial;
	display: block;
	width: 100%;
	resize: none;
	
	${(p) => p.theme.name === merchantThemes.toteme && css`
        font-size: ${rem(15)};
        letter-spacing: ${rem(0.06)};
        line-height: ${rem(20)};
    `}
`
export const ReclamationPopupP = styled.p`
	margin: ${rem(5, 0, 10, 0)};
`
export const ReclamationControlWrapper = styled.div`
	margin-top: ${rem(25)};
`

