import {
    getLoginError,
    getTerms,
    getPolicy,
    getCodes,
    getEmailError,
    getEmailStart
} from "./slices";
import {showPopup, getEmailSuccess} from "../../components/Modal/slices";
import Api from "../../utils/api";

/**
 * getTermsReq {Function}
 * @description - send request to the server to get terms data. Dispatch event to set terms data into Main reducer
 *
 * */
export const getTermsReq = (shopId, locale, countryId) => async (dispatch) => {
    try {
        Api.get(`/faqs/${shopId}/language/${locale}/terms`).then((res) => {
            dispatch(getTerms({
                res,
                countryId
            }));
        });
    } catch (error) {
        dispatch(getLoginError());
    }
};

/**
 * getPolicyReq {Function}
 * @description - send request to the server to get policy data. Dispatch event to set policy data into Main reducer
 *
 * */
export const getPolicyReq = (shopId, locale) => async (dispatch) => {
    try {
        Api.get(`/privacy-policy/latest?language=${locale}&shopId=${shopId}`).then((res) => {
            dispatch(getPolicy(res));
        });
    } catch (error) {
        dispatch(getLoginError());
    }
};

/**
 * getPolicyReq {Function}
 * @description - send request to the server to get policy data. Dispatch event to set policy data into Main reducer
 *
 * */
export const getShopCodes = (shopId, themeName) => async (dispatch) => {
    try {
        Api.get(`/standalone/shop/${shopId}/codes`).then((res) => {
            dispatch(getCodes({codes: res, theme: themeName}));
        });
    } catch (error) {
        dispatch(getLoginError());
    }
};

export const getEmail = (data) => async (dispatch) => {
    try {
        dispatch(getEmailStart())
        Api.post('/email/login', data)
            .then((res) => {
                dispatch(getEmailSuccess(res))
                dispatch(getEmailError())
            })
            .catch((error) => {
                dispatch(showPopup(error))
            })
    } catch (error) {
        dispatch(getEmailError())
    }
}