export const fonts = (family) => {
	switch(family) {
		case 'secondary':
			return {
				fontFamily: 'Playfair',
				fontFamilyTitle: 'Playfair',    
			}
		default:
			return {
				fontFamily: 'Lato',
				fontFamilyTitle: 'Lato',   
			};
	}
}