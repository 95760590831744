import React from 'react'
import Select from 'react-select'
import { LanguageSelectStyle } from './style'
import {useSelector} from "react-redux";
import da from './../../assets/flags_svg/da.svg'
import en from './../../assets/flags_svg/en.svg'
import sv from './../../assets/flags_svg/sv.svg'
import fr from './../../assets/flags_svg/fr.svg'
import es from './../../assets/flags_svg/es.svg'
import de from './../../assets/flags_svg/de.svg'
import it from './../../assets/flags_svg/it.svg'
import no from './../../assets/flags_svg/no.svg'
import fi from './../../assets/flags_svg/fi.svg'
import nl from './../../assets/flags_svg/nl.svg'
import ko from './../../assets/flags_svg/ko.svg'
import jp from './../../assets/flags_svg/jp.svg'
import googleIcon from './../../assets/icons/google-langiage-icon.png'
import {merchantThemes} from "../Variable";

let isGoogleIconHidden = (themeName) => {
	return themeName === merchantThemes["8848"] || themeName === merchantThemes.toteme
}

const getGoogleIcon = (themeName) => {
	return {
		alignItems: 'center',
		display: 'flex',
		position: 'relative',

		':before': {
			backgroundImage: `url(${googleIcon})`,
			border: 0,
			borderRadius: 0,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			content: '" "',
			display: isGoogleIconHidden(themeName) ? 'none' : 'block',
			marginRight: 8,
			height: 18,
			width: 20,

		}
	}
}

const getHoverOptionStyle = (themeName, color) => {
	return themeName === merchantThemes.toteme ? {
		textDecoration: `underline`,
	} : {
		backgroundColor: color.primary,
		color: color.secondary
	}
}

const flagIcon = (code = 'en', isSelected, color, isFlagIcon=false) => {
	return {
		alignItems: 'center',
		display: 'flex',
		position: 'relative',

		':before': {
			backgroundImage: isFlagIcon ? `url(${getFlagBackground(code)})` : 'none',
			border: isFlagIcon ? 0 : `1px solid ${color.grayThree}`,
			borderRadius: isFlagIcon ? 0 : '50%',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			content: '" "',
			display: 'block',
			marginRight: 8,
			height: isFlagIcon ? 18 : 14,
			width: isFlagIcon ? 28 : 14,
		},

		':after': {
			display: isSelected ? 'block': 'none',
			backgroundColor: color.grayThree,
			position: 'absolute',
			borderRadius: '50%',
			content: '" "',
			left: 11,
			marginTop: -5,
			top: '50%',
			height: 10,
			width: 10,
		}
	}
};

const getFlagBackground = (code = 'en') => {
	let url = 'https://img.icons8.com/office/40/000000/usa.png';

	switch (code) {
		case 'en':
			url = en;
			break;
		case 'sv':
			url = sv;
			break;
		case 'fr':
			url = fr;
			break;
		case 'es':
			url = es;
			break;
		case 'de':
			url = de;
			break;
		case 'da':
			url = da;
			break;
		case 'it':
			url = it;
			break;
		case 'no':
			url = no;
			break;
		case 'fi':
			url = fi;
			break;
		case 'nl':
			url = nl;
			break;
		case 'ko':
			url = ko;
			break;
        case 'jp':
			url = jp;
			break;
		default:
			url = '';
			break;
	}


	return url;

};

const LanguageSelect = (props) => {

	const { currentTheme } = useSelector((state) => state.root);
	const { color } = currentTheme;
	const { isMarketSwitcherDisabled, isTokenLogin } = props;

	let combinedProps = {
		...props,
		styles: {
			menu: (styles) => {
				return {
					...styles,
					outline: 'none',
					boxShadow: `none`,
					border: `1px solid ${currentTheme.name === merchantThemes.toteme ? color.primary : color.grayOne}`,
					borderRadius: `${currentTheme.name === merchantThemes.toteme ? '16px' : 0}`
				}
			},
			option: (styles, {data, isSelected}) => {
				return {
					position: 'relative',
					padding: '3px 9px',
					cursor: 'pointer',
                    fontSize: '14px',
					color: currentTheme.name === merchantThemes.toteme ? color.primary : color.grayThree,
					fontWeight: currentTheme.name === merchantThemes.toteme ? 200 : 600,
					...flagIcon(data.value, isSelected, color),

					':hover': getHoverOptionStyle(currentTheme.name, color),
				}
			},
			input: (styles) => {
				return {
					...styles,
					outline: 'none'
				}
			},
			control: (styles) => {
				 return {
				 	...styles,
					 borderColor: currentTheme.name === merchantThemes.toteme ? color.primary : color.grayOne,
					 padding: 0,
					 boxShadow: 'none',
					 borderRadius: `${currentTheme.name === merchantThemes.toteme ? '32px' : 0}`,

					 ':hover': {
						 borderColor: currentTheme.name === merchantThemes.toteme ? color.primary : color.grayOne
					 }
				 }
			},
			placeholder: (styles) => {
				 return {
				 	...styles,
					 outline: 'none'
				 }
			},
			singleValue: () => {
				return {
					padding: '5px 9px 5px 0px',
					fontWeight: currentTheme.name === merchantThemes.toteme ? 200 : 600,
					color: currentTheme.name === merchantThemes.toteme ? color.primary : color.grayThree,
					fontSize: 15,
					...getGoogleIcon(currentTheme.name)
				}
			}
		}

	}
	return (
		<LanguageSelectStyle isMarketSwitcherDisabled={isMarketSwitcherDisabled} isTokenLogin={isTokenLogin}>
			<Select
				{...combinedProps}
				classNamePrefix='react-select'
				//menuIsOpen={true}
			/>
		</LanguageSelectStyle>
	)
}

export default LanguageSelect
