import React from 'react'
import {GlobalStyle} from './style'
import LanguageProvider from '../../components/LanguageProvider'
import {Route, Switch} from 'react-router-dom'
import Main from '../Main'
import {useSelector} from 'react-redux'
import {ThemeProvider} from 'styled-components'
import MainProps from "../MainProperties";
import {MainBackground} from "../Main/style";
import Loader from "../../components/Loader";

const App = () => {
    const {
        currentTheme,
    } = useSelector((state) => state.root);

    return <ThemeProvider theme={currentTheme}>
        <LanguageProvider>
            <MainBackground>
                <MainProps>
                    <GlobalStyle/>
                    <Loader />
                    <Switch>
                        <Route path='/' render={(props) => {
                            return (<Main currentTheme={currentTheme} {...props} />)
                        }}/>
                    </Switch>
                </MainProps>
            </MainBackground>
        </LanguageProvider>
    </ThemeProvider>

}

export default App
